import React, { Component } from 'react';
import { connect } from 'react-redux';

import Notifications from './Notifications';
import HeaderTitle from './HeaderTitle';
import ImageView from '../common/image-view/ImageView';
import PlanSubmissions from './plan-submissions/PlanSubmissions';

import userSession from 'utils/userSession';

import { 
    getFranchiseInfo as getFranchiseInfoAction, 
    resetFranchiseInfo as resetFranchiseInfoAction
} from 'actions/franchiseActions';

import { setMoveToAction } from 'actions/statusAccions';

import { 
    getBrandInfo as getBrandInfoAction 
} from 'actions/brandActions';

import { 
    resetMailingPlan as resetMailingPlanAction 
} from 'actions/mailingPlanActions';
import BudgetGuideFactory from './lmpbp-business-rules/BudgetGuideFactory';

export class Header extends Component {
    componentDidMount() {
        const { getBrandInfo } = this.props;
        getBrandInfo(userSession.getBrandId());
    }

    componentDidUpdate() {
        const { brandConfig } = this.props;
        let root = document.documentElement;

        root.style.setProperty('--brandPrimaryColor', brandConfig?.primaryColor || '#1e9b8c');
        root.style.setProperty('--brandSecondaryColor', brandConfig?.secondaryColor || '#1e9b8c');
        root.style.setProperty('--brandDetailColor', brandConfig?.tertiaryColor || '#1e9b8c');
        root.style.setProperty('--brandPrimaryColor03', `${brandConfig?.primaryColor || '#1e9b8c'}30`);
        root.style.setProperty('--brandSecondaryColor03', `${brandConfig?.secondaryColor || '#1e9b8c'}30`);
        root.style.setProperty('--brandDetailColor03', `${brandConfig?.tertiaryColor || '#1e9b8c'}30`);
    }

    renderFranchiseName = () => {
        const { franchise } = this.props;

        if(userSession.isFranchiseOwer() && franchise?.name) {
            return <div className='user-info-franchise'>{franchise?.name}</div>;
        }

        return null;
    }

    renderSubmissionSelector = () => {
        const {
            currentRoute
        } = this.props;

        const viewingMailingPlan = currentRoute?.path.includes('/mailing-plan');

        if(userSession.isFranchiseOwer() && viewingMailingPlan) {
            return <PlanSubmissions />;
        }
    }

    renderLmpbpBudgetGuide = () => {
        const {
            currentRoute,
            brandConfig
        } = this.props;

        const viewingLmpbp = currentRoute?.path.includes('/lmpbp/territories');

        if(userSession.isFranchiseOwer() && viewingLmpbp) {
            return BudgetGuideFactory(brandConfig);
        }
    }

    onLogout = () => {
        if(this.props.haveUnsavedChanges)
        {
           this.props.setMoveTo('Logout'); 
        }
        else
        {
            userSession.logout();
            window.open(process.env.REACT_APP_LOGOUT_URL, "_self");
        }
    }

    render() {
        const { brandIcon } = this.props;

        return (
            <div className='header-container '>
                <Notifications />
                <div className='d-flex align-items-center'>
                    <div className='border-right ml-4 pr-3'>
                        {
                            brandIcon &&
                                <ImageView 
                                    src={brandIcon}
                                    height='30px'
                                    width='62px'
                                    noPlaceholder={true}
                                    fit='contain'
                                />
                        }
                    </div>
                    <HeaderTitle />
                </div>
                <div className='d-flex align-items-baseline justify-content-center flex-wrap row-gap-1 column-gap-1'>
                    <div className='ml-3 mr-3'>
                        {
                            this.renderSubmissionSelector()
                        }
                        {
                            this.renderLmpbpBudgetGuide()
                        }
                    </div>
                    <div className='user-info'>
                        <i className='far fa-user mr-2'></i>
                        <div className='d-flex flex-column'>
                            <div className='user-info-name'>{userSession.getUserFullName()}</div>
                            {this.renderFranchiseName()}
                        </div>
                    </div>
                    <button 
                        id='header-logout-btn'
                        className='btn btn-link logout' 
                        onClick={() => this.onLogout()}
                    >
                        <i className='fas fa-sign-out-alt mr-2'></i>
                        <div>Logoff</div>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    brandConfig: state.brand,
    mailingPlan: state.mailingPlan,
    brandIcon: state.brand?.icon,
    franchise: state.franchise,
    currentRoute: state.utility?.currentRoute,
    haveUnsavedChanges: state.status.unsavedBudgetPlanChanges,
    moveTo: state.status.moveTo, 
});

export default connect(mapStateToProps, {
    getBrandInfo: getBrandInfoAction,
    getFranchiseInfo: getFranchiseInfoAction,
    resetMailingPlan: resetMailingPlanAction,
    resetFranchiseInfo: resetFranchiseInfoAction,
    setMoveTo: setMoveToAction
})(Header);
