import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import moment from 'moment';
import _ from 'lodash';
import SelectReact from 'react-select';
import { Link } from 'react-router-dom';
// import matchSorter from 'match-sorter';

import Select from 'components/common/select/Select';
import Table from 'components/common/table/Table';
import Panel from 'components/common/panel/Panel';
import Button from 'components/common/button/Button';
import Loader from 'components/common/loader/Loader';
import Switch from 'components/common/switch/Switch';

import { columns } from './config/columns';
import tableDataWrapper from './config/tableDataWrapper';

import {
    LMPBP_GET_BUDGET_PLANS_ERROR,
    LMPBP_GET_FRANCHISES_ERROR,
    LMPBP_GET_STATUS_ERROR,
    LMPBP_GET_FRANCHISES_OWNERS_ERROR,
    LMPBP_GET_PLAN_TEMPLATES_ERROR,
    LMPBP_CREATE_BUDGET_PLAN_ERROR,
    LMPBP_GET_EXPORT_FILE_ERROR,
    LMPBP_SELECT_NULL_PLAN,
    LMPBP_SELECT_ONLY_NULL_PLANS,
    LMPBP_GET_SYNC_FRAN_FRANCHISES_ERROR,
    LMPBP_GET_SYNC_FRAN_FRANCHISES_SUCCESS,
    LMPBP_GET_SYNC_FRAN_FRANCHISEES_ERROR,
    LMPBP_GET_SYNC_FRAN_FRANCHISEES_SUCCESS,
    LMPBP_UPDATE_LOCKDATE_REMINDER_ERROR,
    LMPBP_UPDATE_LOCKDATE_REMINDER_SUCCESS
} from 'actions/types';

import { getFranchises } from 'api/lmpbp/franchises';
import { setCurrentRoute } from 'actions/utilityActions';
import { syncFranconnectFranchises, syncFranconnectFranchisees } from 'api/lmpbp/franconnect';
import { updateLockDateReminder,getBrandLmpbp } from 'api/lmpbp/brand';

import userSession from 'utils/userSession';

import {
    getBudgetPlans,
    getPlanStatuses,
    getFranchiseOwners,
    getBudgetPlansTemplates,
    createBudgetPlan,
    exportBudgetPlan
} from 'api/lmpbp/budgetPlans';

import {
    closeNotification,
    showNotification,
    notifySyncSuccess,
    notifyUpdateLocakDateReminderSuccess,
    notifyUpdateLocakDateReminderError
} from 'actions/notificationActions';
import { useHistory } from 'react-router-dom';

const LmpbpDashboard = props => {
    const [loadedMessage, setLoadedMessage] = useState("Loading...");
    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            if (row.id != null) {
                setSelectedPlans([...selectedPlans, row]);
                setIsExport(true);
            } else {
                dispatch(
                    showNotification({
                        id: LMPBP_SELECT_NULL_PLAN,
                        message: 'This plan is not able to be exported',
                        type: 'danger',
                    })
                );
                setSelectedPlans(
                    selectedPlans.filter(({ id }) => id !== row.id)
                );
                return false
            }
        } else {
            setSelectedPlans(
                selectedPlans.filter(({ id }) => id !== row.id)
            );
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (!isSelect) {
            setSelectedPlans([]);
            return;
        }

        let error = false;
        let filteredArray = rows.filter(function(itm) {
            if (itm.id == null) {
                error = true;
            }
            else
                return itm.id;
        });

        if (error && filteredArray.length > 0) {
            dispatch(
                showNotification({
                    id: LMPBP_SELECT_NULL_PLAN,
                    title: "Can't select all the plans",
                    message: 'Some plans are not able to be selected',
                    type: 'warning',
                })
            );

            setSelectedPlans([...filteredArray]);
            setIsExport(true);
        } else if (error && filteredArray.length == 0) {
            dispatch(
                showNotification({
                    id: LMPBP_SELECT_ONLY_NULL_PLANS,
                    title: "Not submited plans",
                    message: 'These plans are not able for be exported',
                    type: 'danger',
                })
            );

            setSelectedPlans([]);
            setIsExport(false);
        }

        if (isSelect) {
            setSelectedPlans([...filteredArray]);
            setIsExport(true);

            return rows.filter(r => r.id != null).map(r => r.id);
        }
    }

    const history = useHistory();
    const dispatch = useDispatch();

    const [isExport, setIsExport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [row, setRow] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [selectedFranchiseId, setSelectedFranchiseId] = useState(null);
    const [selectedStatusName, setSelectedStatusName] = useState('Submitted');
    const [selectedOwnerId, setSelectedOwnerId] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new moment().year());
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [filteredSelectedPlans, setFilteredSelectedPlans] = useState([]);
    const [selectedOnLockDateReminder, setIsOnLockDateReminder] = useState(false);

    useEffect(() => {
        document.title = 'Local Marketing Plan Budget Platform';
        document.getElementById("favicon").href = window.location.origin + '/lmpbp.png';
        if (selectedPlans.length === 0) {
            setIsExport(false);
        }
    });

    useEffect(() => {
        if (planId) {
            const state =
            {
                budgetPlan:
                {
                    id: planId,
                    brandId: row.budgetPlan.brandId,
                    externalId: row.budgetPlan.externalId,
                    franchiseId: row.budgetPlan.franchiseId,
                    franchiseName: row.budgetPlan.franchiseName,
                    requiredAnnualAmount: row.budgetPlan.requiredAnnualAmount,
                    requiredMonthlyAmount: row.budgetPlan.requiredMonthlyAmount,
                    directMailAccess: row.budgetPlan.directMailAccess,
                    contactEmail: row.budgetPlan.contactEmail,
                    templateId: row.budgetPlan.templateId,
                    statusId: row.budgetPlan.statusId,
                    status: row.budgetPlan.status,
                    createdOn: row.budgetPlan.createdOn,
                    updatedOn: row.budgetPlan.updatedOn,
                    owners: row.budgetPlan.owners,
                    totalSpend: row.budgetPlan.totalSpend
                },
                templateYear: row.templateYear,
                templateId: row.templateId,
                owners: row.owners,
                franchiseName: row.franchise,
            };

            history.push(`/lmpbp/dashboard/${planId}`, state);
        }
    }, [planId]);

    useEffect(() => {
        setFilteredSelectedPlans(filterSelectedPlans());
    }, [selectedPlans, selectedStatusName, selectedYear, selectedOwnerId, selectedFranchiseId])

    const filterSelectedPlans = () => {
        return selectedPlans.filter((plan) => {
            return plan.templateYear == selectedYear &&
                (selectedStatusName == "All" || plan.status == selectedStatusName) &&
                (!selectedFranchiseId || plan.budgetPlan.franchiseId == selectedFranchiseId.value) &&
                (!selectedOwnerId || plan.budgetPlan.owners.find((owner) => owner.id == selectedOwnerId.value));
        });
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selected: selectedPlans.map((plan) => plan.key)
    };

    const createPlan = useMutation(
        ({ franchiseId, templateId } = {}) =>
            createBudgetPlan({ franchiseId, templateId }),
        {
            onError: error => {
                dispatch(
                    showNotification({
                        id: LMPBP_CREATE_BUDGET_PLAN_ERROR,
                        message: 'Error creating budget plan',
                        type: 'danger',
                        serverError: error,
                    })
                );
            },
            onSuccess: (res) => {
                dispatch(closeNotification(LMPBP_CREATE_BUDGET_PLAN_ERROR));
                setPlanId(res.id);
            },
        }
    );

    const budgetPlans = useQuery(
        {
            queryKey: ['budgetPlans', selectedYear],
            queryFn: () => getBudgetPlans(selectedYear),
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_BUDGET_PLANS_ERROR,
                        message: 'Error fetching budget plans',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: data => {
                dispatch(closeNotification(LMPBP_GET_BUDGET_PLANS_ERROR));
            },
        }
    );

    const franchises = useQuery(
        ['franchises'],
        async () => await getFranchises(),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_FRANCHISES_ERROR,
                        message: 'Error fetching franchise',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_GET_FRANCHISES_ERROR));
            },
        }
    );

    const statuses = useQuery(
        ['statuses'],
        async () => await getPlanStatuses(),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_STATUS_ERROR,
                        message: 'Error fetching status',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_GET_STATUS_ERROR));
            },
        }
    );

    const owners = useQuery(
        ['owners'],
        async () => await getFranchiseOwners(),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_FRANCHISES_OWNERS_ERROR,
                        message: 'Error fetching franchise owners',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_GET_FRANCHISES_OWNERS_ERROR));
            },
        }
    );

    const templates = useQuery(
        {
            queryKey: ['templates'],
            queryFn: () => getBudgetPlansTemplates(),
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_PLAN_TEMPLATES_ERROR,
                        message: 'Error fetching budget plan templates',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: (data) => {
                dispatch(closeNotification(LMPBP_GET_PLAN_TEMPLATES_ERROR));
                const currentYearTemplate = data.find((template) => template.year == selectedYear);
                if (currentYearTemplate) {
                    setSelectedTemplate(currentYearTemplate);
                }
                else {
                    const mostRecentTemplate = data[0];
                    setSelectedTemplate(mostRecentTemplate);
                    setSelectedYear(mostRecentTemplate.year);
                }
            },
        }
    );

    const onExportData = useQuery(
        ['onExportData', filteredSelectedPlans],
        async () =>
            await exportBudgetPlan({
                budgetPlanIds: filteredSelectedPlans.map(function(a) { return a.id; }),
            }),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_EXPORT_FILE_ERROR,
                        message: 'Error fetching Export File',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: res => {
                dispatch(closeNotification(LMPBP_GET_EXPORT_FILE_ERROR));
                var link = document.createElement("a");
                link.href = res;
                document.body.appendChild(link);
                link.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(link);
                }, 200);
                setIsLoading(false)
            },
        }
    );

    // Franconnect Sync //

    const syncFranFranchises = useQuery(
        ['syncfranfranchises'],
        async () => await syncFranconnectFranchises(),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_SYNC_FRAN_FRANCHISES_ERROR,
                        message: 'Error in sync franchise',
                        type: 'danger',
                        serverError: err,
                    })
                );
                setIsLoading(false)
            },
            onSuccess: () => {
                dispatch(closeNotification(LMPBP_GET_SYNC_FRAN_FRANCHISES_SUCCESS));
                syncFranFranchisees.refetch();
            },
        }
    );

    const syncFranFranchisees = useQuery(
        ['syncfranfranchisees'],
        async () => await syncFranconnectFranchisees(),
        {
            enabled: false,
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_SYNC_FRAN_FRANCHISEES_ERROR,
                        message: 'Error in sync franchisees',
                        type: 'danger',
                        serverError: err,
                    })
                );
                setIsLoading(false)
            },
            onSuccess: () => {
                dispatch(
                    notifySyncSuccess({
                        id: LMPBP_GET_SYNC_FRAN_FRANCHISEES_SUCCESS,
                        message: 'Sync Successfully!',
                        type: 'success',
                        serverError: null,
                    })
                );
                setIsLoading(false)
            },
        }
    );
    const toggleLockDateReminder = useMutation(
        ({ toggle_lock_date_reminder } = {}) =>
            updateLockDateReminder({ toggle_lock_date_reminder: toggle_lock_date_reminder ? 1:0}),
        {
            onError: error => {
                dispatch( notifyUpdateLocakDateReminderError({id: LMPBP_UPDATE_LOCKDATE_REMINDER_ERROR}));
                setIsLoading(false);
            },
            onSuccess: (res) => {
                dispatch(notifyUpdateLocakDateReminderSuccess({id:LMPBP_UPDATE_LOCKDATE_REMINDER_SUCCESS}));
                setTimeout(function() {
                    dispatch(notifyUpdateLocakDateReminderSuccess({id:LMPBP_UPDATE_LOCKDATE_REMINDER_SUCCESS,display:false}) );
                }, 2000);
                setIsLoading(false);
            },
        }
    );
    const getBrandData = useQuery(
        {
            queryFn: () =>  getBrandLmpbp({ brandId : userSession.getBrandId() }),
            onError: err => {
                dispatch(
                    showNotification({
                        id: LMPBP_GET_PLAN_TEMPLATES_ERROR,
                        message: 'Error in loading brand',
                        type: 'danger',
                        serverError: err,
                    })
                );
            },
            onSuccess: (data) => {
                dispatch(closeNotification(LMPBP_GET_PLAN_TEMPLATES_ERROR));
                setIsOnLockDateReminder(data.toggle_lock_date_reminder);
            },
        }
    );
    const generateStatusOptions = () => {
        let options = [
            {
                label: 'All',
                value: 'All',
            },
        ];

        if (statuses.data) {
            const allStatuses = [
                ...statuses.data,
                {
                    name: 'Not Submitted',
                },
            ];

            options = [
                ...options,
                ..._.sortBy(
                    allStatuses.map(({ name }) => ({
                        value: name,
                        label: name !== 'Draft' ? name : 'In Progress',
                    })),
                    ['value']
                ),
            ];
        }

        return options;
    };

    const generateFranchiseOptions = () => {
        let options = [
            {
                label: 'All Franchises',
                value: 'All Franchises',
            },
        ];

        if (franchises.data) {
            franchises.data.forEach(({ id, name }) => {
                options.push({
                    value: id,
                    label: name,
                });
            });
        }

        return options;
    };

    /**
 * Function to sort alphabetically an array of objects by some specific key.
 * 
 * @param {String} property Key of the object to sort.
 */
    function dynamicSort(property) {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function(a, b) {
            if (sortOrder == -1) {
                return b[property].localeCompare(a[property]);
            } else {
                return a[property].localeCompare(b[property]);
            }
        }
    }
    const generateOwnersOptions = () => {
        let options = [];

        if (owners.data) {
            let filterdOwners = {};
            owners.data.forEach(owner => {
                filterdOwners[owner.id] = owner;
            });

            Object.values(filterdOwners).forEach(({ id, name, lastName }) => {
                options.push({
                    value: id,
                    label: `${name} ${lastName}`,
                });
            });
        }
        options.sort(dynamicSort("label"));
        options.unshift({
            label: 'All',
            value: 'All',
        },)
        return options;
    };

    const getYearOptions = () => {
        return templates.data?.map(t => ({
            value: t.year,
            label: t.year,
        }));
    };

    const onCreateNewPlan = row => {
        let newRow = row;
        const templateId = selectedTemplate.id;
        newRow.budgetPlan.templateId = templateId;
        setRow(newRow);
        createPlan.mutate({
            franchiseId: row.budgetPlan.franchiseId,
            templateId,
        });
    };

    const applyFilters = () => {
        let filtered = budgetPlans.data || [];

        filtered = filtered.map((plan) => {
            if (!plan.id)
                return { ...plan, onCreateNewPlan };
            else
                return { ...plan };
        });

        if (filtered) {
            if (
                selectedFranchiseId &&
                selectedFranchiseId.value !== 'All Franchises'
            ) {
                filtered = filtered.filter(
                    ({ franchiseId }) =>
                        Number(franchiseId) === Number(selectedFranchiseId.value)
                );
            }

            if (selectedStatusName && selectedStatusName !== 'All') {
                filtered = filtered.filter(
                    ({ status }) => status === selectedStatusName
                );
            }

            if (selectedOwnerId && selectedOwnerId.value !== 'All') {
                filtered = filtered.filter(
                    ({ owners }) =>
                        owners.filter(
                            ({ id }) => Number(id) === Number(selectedOwnerId.value)
                        ).length
                );
            }
        }

        return filtered;
    };

    useEffect(() => {
        franchises.refetch();
        statuses.refetch();
        owners.refetch();
        templates.refetch();
        getBrandData.refetch();
        dispatch(setCurrentRoute(props.match));
    }, []);

    useEffect(() => {
        if (!templates.data) return;
        const template = templates.data?.find(template => template.year === selectedYear);
        setSelectedTemplate(template);
    }, [selectedYear]);

    return (
        <Panel>
            <div className='d-flex justify-items-between column-gap-2' id="dashboard-budget">
                <div className='flex-grow-1' id="dashboard-budget-select-franchise">
                    <div className="d-flex flex-column">
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                            Franchise
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                                placeholder={'Search for Franchise'}
                                isClearable={true}
                                value={selectedFranchiseId}
                                onChange={setSelectedFranchiseId}
                                options={generateFranchiseOptions()}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-grow-1' id="dashboard-budget-select-owner">
                    <div className="d-flex flex-column">
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                            Owner
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                                placeholder={'Enter Owner name'}
                                isClearable={true}
                                value={selectedOwnerId}
                                onChange={setSelectedOwnerId}
                                options={generateOwnersOptions()}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex-grow-1' id="dashboard-budget-select-year">
                    <Select
                        id='year'
                        label='Year'
                        icon='far fa-calendar'
                        labelStyling='font-family-bold c-neutral font-size-14 mb-1'
                        options={getYearOptions()}
                        value={selectedYear}
                        onChange={({ target: { value } }) => {
                            setSelectedYear(Number(value))
                        }}
                    />
                </div>
                <div className='flex-grow-1' id="dashboard-budget-select-status">
                    <Select
                        id='status'
                        label='Status'
                        icon='far fa-clock'
                        labelStyling='font-family-bold c-neutral font-size-14 mb-1'
                        options={generateStatusOptions()}
                        value={selectedStatusName}
                        onChange={({ target: { value } }) =>
                            setSelectedStatusName(value)
                        }
                    />
                </div>
            </div>

            <div
                id='dashboard-budget-plans-table'
                className='budget-plans-table pt-4'
            >
                <Table
                    keyField='key'
                    data={tableDataWrapper(
                        applyFilters(),
                        selectedYear,
                        selectedTemplate?.id
                    )}
                    columns={columns}
                    enablePagination={true}
                    selectRow={selectRow}
                    loading={budgetPlans.isLoading || createPlan.isLoading}
                    loadingMessage={'Loading budget plans...'}
                    defaultSorted={[
                        {
                            dataField: 'id',
                            order: 'desc',
                        },
                    ]}
                />
            </div>
            {isLoading
                ? <Loader loadingMessage={loadedMessage} />
                : <div className='d-flex column-gap-1'>
                    <Button
                        action={() => { onExportData.refetch(); setIsLoading(true); }}
                        btnType='btn-primary'
                        label='Export Selected'
                        disabled={isExport ? false : true}
                    />
                    <Link
                        to={{
                            pathname: "/lmpbp/export-plans",
                            state: { key: "PulseM", year: selectedYear }
                        }}
                    >
                        <Button
                            action={() => { }}
                            btnType='outline-primary'
                            label='Export monthly budgets by tactic'
                        />
                    </Link>
                    <Button
                        action={() => {
                            syncFranFranchises.refetch();
                            setLoadedMessage("Syncing...");
                            setIsLoading(true);
                        }
                        }
                        btnType='outline-primary'
                        label='Sync FranConnect'
                    />
                    <Link to={{
                        pathname: "/lmpbp/websites",
                        state: { selectedTemplate: selectedTemplate }
                    }}>
                        <Button
                            action={() => { }}
                            btnType='outline-primary'
                            label='Manage websites'
                        />
                    </Link>
                    <div class="col-3 toggle-reminder">
                        <Switch
                            id={`toggle-budget-lock-date-reminder`}
                            label= {`${selectedOnLockDateReminder?'On' :'Off'} Budget lock Date Reminder`}
                            labelTop={false}
                            checked={selectedOnLockDateReminder}
                            onToggle={(checked) => {
                                setIsLoading(true);
                                setLoadedMessage("Updating lock date reminder...");
                                setIsOnLockDateReminder(checked?1:0)
                                toggleLockDateReminder.mutate({
                                    toggle_lock_date_reminder: checked
                                })
                            }
                            }
                            />
                    </div>
                </div>
            }
        </Panel>
    );
};

export default LmpbpDashboard;
