import validateRole from './validateRole';

const USER_SESSION = 'user_session';
const USER_TOKEN = 'user_token';
const ARRIVED_AT_LMPBP = 'arrived_at_lmpbp';
const LMPBP_USER_TOKEN = 'lmpbp_user_token';

const arrivedAtLmpbp = () => {
    const arrivedAtLmpbpJSON = sessionStorage.getItem(ARRIVED_AT_LMPBP);
    return JSON.parse(arrivedAtLmpbpJSON);
};

const sessionData = () => {
    const sessionJSON = sessionStorage.getItem(USER_SESSION);
    return JSON.parse(sessionJSON);
};

const userSession = {
    set: newData => sessionStorage.setItem(USER_SESSION, JSON.stringify({ ...sessionData(), ...newData })),
    setDirectMailToken: token => sessionStorage.setItem(USER_TOKEN, token),
    getDirectMailToken: () => sessionStorage.getItem(USER_TOKEN),
    setArrivedAtLmpbp: (arrivedAtLmpbp = false) => sessionStorage.setItem(ARRIVED_AT_LMPBP, arrivedAtLmpbp),
    arrivedAtLmpbp: () => arrivedAtLmpbp() === true,
    setLmpbpToken: token => sessionStorage.setItem(LMPBP_USER_TOKEN, token),
    getLmpbpToken: () => sessionStorage.getItem(LMPBP_USER_TOKEN),
    isDivisionalUser: () => validateRole.isDivisionalUser(sessionData()?.UserType),
    isFranchiseOwer: () => validateRole.isFranchiseOwner(sessionData()?.UserType),
    getUser: () => sessionData(),
    getUserId: () => sessionData()?.UserID,
    getUserRole: () => sessionData()?.UserType,
    getBrandId: () => sessionData()?.BrandID,
    getBrand:()=>sessionData()?.Brand,
    getBrandSymbol: () => sessionData()?.Symbol,
    getFranchiseId: () => sessionData()?.FranchiseID,
    getFranchiseName: () => sessionData()?.FranchiseName,
    getFranchiseExternalId: () => sessionData()?.FranchiseExternalID,
    getUserFullName: () => sessionData()?.Name,
    getUserFirstName: () => sessionData()?.FirstName,
    getUserLastName: () => sessionData()?.LastName,
    getUserEmail: () => sessionData()?.Email,
    isActive: () => sessionData() ? true : false,
    logout: () => sessionStorage.clear(),
    isMSQUser:()  =>validateRole.isMSQDMUser(sessionData()?.BrandID),
    isASPUser:()  =>validateRole.isASPUser(sessionData()?.BrandID),
    isMTUser:()  =>validateRole.isMTUser(sessionData()?.BrandID),
    isMSQLmpbpUser:()  =>validateRole.isMSQLmpbpUser(sessionData()?.BrandID),
    isDcsLmpbpUser:()  =>validateRole.isDcsLmpbpUser(sessionData()?.BrandID),
    isCWPLmpbpUser:() => validateRole.isCWPLmpbpUser(sessionData()?.BrandID),
    isWPVLmpbpUser: () => validateRole.isWPVLmpbpUser(sessionData()?.BrandID),
    isOHALmpbpUser:()  =>validateRole.isOHALmpbpUser(sessionData()?.BrandID),
    isBFPLmpbpUser:()  =>validateRole.isBFPLmpbpUser(sessionData()?.BrandID),
    isMSELmpbpUser:()  =>validateRole.isMSELmpbpUser(sessionData()?.BrandID),
    isHWCLmpbpUser:() => validateRole.isHWCLmpbpUser(sessionData()?.BrandID),
    getToggleLockDateReminder: () => Number(sessionStorage.getItem("toggle_lock_date_reminder")),
    getToggleLockDateReminderDm: () => Number(sessionStorage.getItem("toggle_lock_date_reminder_dm")),
};

export default userSession;