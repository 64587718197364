import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import NavButton from 'components/common/nav-button/NavButton';
import Overview from './Overview';
import MailingPlans from './MailingPlans';
import ToggleReminder from './ToggleReminder';
import userSession from 'utils/userSession';
import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';

import {
    sendEmailReminders as sendEmailRemindersAction,
    syncFranConnect as syncFranConnectAction,
    showConfirmEmailReminders as showConfirmEmailRemindersAction,
} from 'actions/dashboardActions';

import { resetMailingPlan as resetMailingPlanAction } from 'actions/mailingPlanActions';

import ConfirmEmailNotificationsModal from './ConfirmEmailNotificationsModal';

export class Dashboard extends Component {
    state = {
        //hide Manage Address List Button if brand is MSQ or MT
        hideAddressListButtonForMSQ:  userSession.isMSQUser() || userSession.isMTUser()  ? true :false,
        //show Manage Franchise List Button if brand is ASP
        showManageFranchiseForASP:  userSession.isASPUser() ||   userSession.isMSQUser() ? true :false,
    };
    componentDidMount() {
        this.props.setCurrentRoute(this.props.match);
        this.props.resetMailingPlan(null);
        document.getElementById("manage-address-list-nav-btn").style.display = this.state.hideAddressListButtonForMSQ ?'none':'';
        document.getElementById("manage-franchise-list-nav-btn").style.display = this.state.showManageFranchiseForASP ?'':'none';
    }

    render() {
        const {
            syncFranConnect,
            synchingFranConnect,
            showConfirmEmailReminders,
            confirmEmailReminders,
            sendEmailReminders,
        } = this.props;
        return (
            <Fragment>
                <ConfirmEmailNotificationsModal
                    sendEmailReminders={sendEmailReminders}
                    showConfirmEmailReminders={showConfirmEmailReminders}
                    confirmEmailReminders={confirmEmailReminders}
                />
                <div className='dashboard-layout'>
                    <div className='dashboard-overview'>
                        <Overview />
                    </div>
                    <div className='dashboard-navigation'>
                        <NavButton
                            id='generate-reports-nav-btn'
                            label='Generate Reports'
                            icon='fas fa-file-csv'
                            to='/reports'
                        />
                        <NavButton
                            id='manage-address-list-nav-btn'
                            label='Manage Address List'
                            icon='fas fa-file-import'
                            to='/import-addresses'
                        />
                        <NavButton
                            id='manage-mailing-plans-nav-btn'
                            label='Manage Mailing Plans'
                            icon='fas fa-cogs'
                            to='/yearly-plans'
                        />
                        <NavButton
                            id='manage-exports-nav-btn'
                            label='Manage Exports For Printers'
                            icon='fas fa-calendar-alt'
                            to='/export-campaigns'
                        />
                        <NavButton
                            id='sync-franconnect-nav-btn'
                            label='Sync FranConnect'
                            icon='fas fa-sync-alt'
                            to=''
                            loading={synchingFranConnect}
                            loadingMessage='Synchronizing...'
                            disabled={synchingFranConnect}
                            onClickCallback={() => syncFranConnect()}
                        />
                        <NavButton
                            id='campaign-reminders-nav-btn'
                            label='Send Campaign Reminders'
                            icon='fas fa-mail-bulk'
                            onClickCallback={() =>
                                showConfirmEmailReminders(true)
                            }
                        />
                         <NavButton
                            id='manage-franchise-list-nav-btn'
                            label='Manage Franchise List'
                            icon='fas fa-users'
                            to='/franchises'
                        />
                         <div className='dashboard-overview'>
                            <ToggleReminder />
                        </div>
                      
                    </div>
                    <div className='dashboard-content'>
                        <MailingPlans />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    synchingFranConnect: state.dashboard?.synchingFranConnect,
    confirmEmailReminders: state.dashboard?.confirmEmailReminders,
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    syncFranConnect: syncFranConnectAction,
    resetMailingPlan: resetMailingPlanAction,
    showConfirmEmailReminders: showConfirmEmailRemindersAction,
    sendEmailReminders: sendEmailRemindersAction,
})(Dashboard);
