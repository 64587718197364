import React, { useContext, useState } from "react";
import { WebsiteContext } from "./WebsiteCard";
import { useQuery } from "react-query";
import { getTemplateTactics } from "api/lmpbp/budgetPlans";
import AddFranchiseForm from "./AddFranchiseForm";
import SelectReact from "react-select";

function AddParentFranchiseForm({ onClose }) {
  const { template } = useContext(WebsiteContext);
  const [selectedTactics, setSelectedTactics] = useState([]);

  const templateTacticsQuery = useQuery(
    ['template_tactics', template.id],
    () => getTemplateTactics(template.id)
  );

  return (
    <div>
      <SelectReact
        isMulti
        value={selectedTactics}
        onChange={setSelectedTactics}
        options={templateTacticsQuery.data?.map(t => ({
          label: t.displayName,
          value: t,
        }))}
        placeholder="Choose Tactics"
      />
      <AddFranchiseForm
        onClose={onClose}
        tactics={selectedTactics.map(t => t.value)}
        placeholder="Choose Parent Franchise"
        isParent={true}
      />
    </div>
  );
}

export default AddParentFranchiseForm;
