import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { ReactComponent as NotSubmitted } from 'assets/svgs/notsubmitted.svg';
import { ReactComponent as Submitted } from 'assets/svgs/submitted.svg';
import { ReactComponent as Approved } from 'assets/svgs/approved.svg';
import { ReactComponent as Rejected } from 'assets/svgs/rejected.svg';

import { LMPBP_GET_BUDGET_PLANS_ERROR } from 'actions/types';

import TerritoryBudgetPlan from './territory-budget-plan/TerritoryBudgetPlan';
import Loader from 'components/common/loader/Loader';
import CustomTabs from 'components/common/custom-tabs/custom-tabs';
import ConfirmationModal from 'components/common/confirmation-modal/ConfirmationModal';

import { getFranchises } from 'api/lmpbp/franchises';
import { setCurrentRoute } from 'actions/utilityActions';
import {
    closeNotification,
    showNotification,
} from 'actions/notificationActions';
import {
    LMPBP_SET_HAVE_UNSAVED_CHANGES
} from 'actions/types';
import moment from 'moment';

const Territories = props => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0);
    const [selectedYear, setSelectedYear] = useState(new moment().year());
    const haveUnsavedChanges = useSelector((state) => state.status.unsavedBudgetPlanChanges);
    const [isSwitch, setSwitch] = useState(null);

    const {
        isLoading,
        error,
        data = [],
    } = useQuery('territories', async () => await getFranchises());
    const handleChange = ( newValue) => {
        if(haveUnsavedChanges)
        {
            setSwitch(newValue);
        }else{
            setActiveTab(newValue);
        }
      };
      const onConfirmAction = ( ) => {
        dispatch({ type: LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: false });
        setActiveTab(isSwitch);
        setSwitch(null);
      };
      const onCancel = ( ) => {
        setSwitch(null);
        dispatch({ type: LMPBP_SET_HAVE_UNSAVED_CHANGES, payload: true });
      };
    useEffect(() => {
        document.title = 'Local Marketing Plan Budget Platform';
        document.getElementById("favicon").href = window.location.origin + '/lmpbp.png';
        dispatch(setCurrentRoute(props.match));
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(
                showNotification({
                    id: LMPBP_GET_BUDGET_PLANS_ERROR,
                    message: 'Error fetching latest budget plans',
                    type: 'danger',
                    serverError: error,
                })
            );
        } else {
            dispatch(closeNotification(LMPBP_GET_BUDGET_PLANS_ERROR));
        }
    }, [error]);

    // TODO: implement status renderer
    // this requires a new endpoint to fetch the latest status for a franchise's budget plan
   
    const calcTabsPerSlide = () =>
    {
        const width = window.innerWidth;

        if(width >= 1920) return 9;
        if(width >= 1280) return 6;
        else if(width >= 768) return 4;
    }

    const renderTabs = () =>
    {
        const tabs =  data?.map((franchise, index) => {
            return {
                name: franchise.name, 
                index: index,
                content: index == activeTab
                    ? <TerritoryBudgetPlan
                        franchise={franchise}
                        persistedYear={selectedYear}
                        setPersistedYear={setSelectedYear} />
                    : null
            };
        }); 
        return <CustomTabs
            tabs={tabs}
            subclass="territory"
            tabsPerSlide={calcTabsPerSlide()}
            activeTab={activeTab}
            setActiveTab={(k) => handleChange(k)}
            selectedYear={selectedYear}
        />
    }
    return (
        <><Fragment>
            {isLoading ? (
                <Loader loadingMessage='Loading territories...' />
            ) : (
                <div className='territories-tabs' id="territories-tabs">
                    {renderTabs()}
                </div>
            )}
        </Fragment><ConfirmationModal
                show={isSwitch}
                centered
                type='warning'
                headerType='warning'
                headerIconLeft='fas fa-exclamation-triangle'
                headerIconRight='fas fa-exclamation-triangle'
                header='Unsaved changes'
                confirmBtnLabel='Navigate away'
                showConfirmBtn={true}
                showCancelBtn={true}
                onClose={onCancel}
                onConfirm={onConfirmAction}
                isCustomTabFlag={true}
                message='Your plan has unsaved changes, are you sure you want to navigate away?' /></>
    );
};

export default Territories;
